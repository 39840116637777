<template>
  <Header></Header>
  <div id="train">
    <div class="train-content">
      <div class="train-head">
        <div class="train">
          <img src="@/assets/img/fas_fa-random.svg" alt="" />
          <h3>实训中心</h3>
        </div>
      </div>

      <div class="train-info-box">
        <el-tabs v-model="activeName">
          <el-tab-pane label="全部实训" name="first">
            <div class="tra-con">
              <div class="train-info">
                <div
                  class="train-item"
                  v-for="item in trainAllDetail"
                  :key="item.traId"
                >
                  <router-link
                    :to="{
                      path: '/traningdetail',
                      query: { trainingId: item.traId },
                    }"
                  >
                    <div class="train-item-img">
                      <img
                        v-if="item.enterpriseName == null"
                        src="/img/item-logo/default.svg"
                        alt=""
                      />
                      <img v-else :src="trainLogo" alt="" />
                    </div>
                    <div class="train-item-text">
                      <h3>{{ item.traName }}</h3>
                      <span>{{ item.traStartTime }}-{{ item.traEndTime }}</span>
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
  <Footer></Footer>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
export default {
  name: "Training",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      activeName: "first",
      dialogFormVisible: false,
      userId: localStorage.getItem("userId"),
      form: {
        name: "",
        content: "",
      },
      formLabelWidth: "120px",
      trainAllDetail: "",
      trainLogo:
        "/img/item-logo/" + localStorage.getItem("enterpriseName") + ".svg",
    };
  },
  created() {
    this.axios
      .get("/training/selectAllTraining", { params: { userId: this.userId } })
      .then((response) => (this.trainAllDetail = response.data));
  },
};
</script>

<style scoped>
.el-button--text {
  border-color: transparent;
  color: #fff;
}

#train /deep/.el-tabs__item {
  /* 目前train使用单选项卡 需要关闭padding 多选项卡开启 */
  /* padding: 0 60px 0 0; */
  height: 45px;
  font-size: 20px;
  font-weight: 540;
}

#train /deep/.el-tabs__active-bar {
  height: 3px;
}

#train /deep/.el-tabs__item.is-active {
  font-weight: bold;
  color: #1a80ff;
}

#train /deep/.el-tabs__nav-wrap::after {
  height: 2px;
  background-color: #1a80ff;
}

/* training */

.train-content,
.review-content {
  max-width: 1240px;
  margin: 0 auto;
  min-height: 600px;
  padding: 35px 0 88px;
}

.train-head {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.train-head .train {
  display: flex;
  justify-content: space-between;
  width: 150px;
}

.train-head .train img {
  margin-right: 16px;
}

.train-head .train h3 {
  color: rgba(30, 32, 36, 100);
  font-size: 24px;
  font-family: SourceHanSansSC-bold;
}

.train-head .create {
  width: 112px;
  height: 36px;
  line-height: 36px;
  border-radius: 2px 2px 2px 2px;
  background-color: rgba(26, 128, 255, 100);
  text-align: center;
  color: rgba(255, 255, 255, 100);
  font-size: 14px;
  cursor: pointer;
}

.train-title span {
  width: 96px;
  display: inline-block;
  margin-right: 24px;
  color: rgba(30, 32, 36, 100);
  font-size: 20px;
  text-align: center;
  font-family: SourceHanSansSC-regular;
  cursor: pointer;
}

.train-title {
  border-bottom: 1px solid rgb(26 128 255 / 40%);
}

.train-title span.active {
  color: rgba(26, 128, 255, 100);
  font-family: SourceHanSansSC-bold;
  font-weight: 550;
  border-bottom: 2px solid rgba(26, 128, 255, 100);
  padding-bottom: 10px;
}

.train-info {
  display: flex;
  flex-wrap: wrap;
}

.train-info-box .tra-con:first-child {
  display: block;
}

.train-item {
  width: 346px;
  height: 120px;
  margin-top: 40px;
  margin-right: 98px;
  border: 1px solid rgba(187, 187, 187, 100);
}

.train-item a {
  display: flex;
  align-items: center;
}

.train-item:nth-child(3n) {
  margin-right: 0;
}

.train-item-img {
  width: 100px;
  height: 120px;
  border-right: 1px solid rgba(187, 187, 187, 100);
  background-color: #ecf5ff;
  position: relative;
}

.train-item-img img {
  height: 40%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.train-item-text {
  width: 226px;
  padding-left: 16px;
}

.train-item-text h3 {
  color: rgba(16, 16, 16, 100);
  font-size: 18px;
  font-family: Roboto-bold;
  margin-bottom: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.train-item-text span {
  color: rgba(75, 77, 83, 100);
  font-size: 14px;
  font-family: Roboto;
}
</style>
