<template>
  <Header></Header>
  <div class="personalCenter-detail" id="personalCenterProject-detail">
    <div class="title">
      <img src="/img/fas_fa-folder-open.svg" alt="" />
      <h3>我的案例</h3>
    </div>
    <div class="des">
      <div class="per-content">
        <p>
          案例名称：<span>{{ Detail.projectName }}</span>
        </p>
        <p>发布时间： {{ Detail.createTime }}</p>
        <p>
          案例编号：<span>{{ Detail.projectId }}</span>
        </p>
        <p>温馨提示：进入编程界面后,请勿修改文件名称，可能会造成文件丢失。</p>
      </div>
      <div class="button">
        <span @click="begin()" class="begin">开始编程</span>
        <span @click="openDelete()" class="delete">删除案例</span>
      </div>
    </div>
  </div>
  <Footer></Footer>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import { ElMessage } from "element-plus";
export default {
  name: "PersonalCenterProjectgDetail",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      Detail: "",
      jupyterhub: localStorage.getItem("jupyterhub"),
    };
  },
  methods: {
    begin() {
      window.open(
        "http://jupyter.ditwin.cn:8000/hub/login?token=" +
          this.jupyterhub +
          "&next=/user/" +
          localStorage.getItem("username") +
          "/notebooks/案例项目/" +
          this.Detail.projectName +
          "/" +
          this.Detail.projectName +
          ".ipynb"
      );
    },
    openDelete() {
      this.$confirm("此操作将永久删除该案例, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.deleteCase();
          let _this = this;
          setTimeout(function () {
            _this.$router.push({ path: "/personalCenter" });
          }, 2000);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    deleteCase() {
      this.axios
        .get("/project/deleteProjectUser", {
          params: {
            projectId: this.$route.query.projectId,
            userId: localStorage.getItem("userId"),
            username: localStorage.getItem("username"),
            projectName: this.Detail.projectName,
          },
        })
        .then((response) => {
          this.$message({
            type: "success",
            message: response.data.msg,
          });
        });
    },
  },
  created() {
    this.axios
      .get("/project/selectOne", {
        params: {
          projectId: this.$route.query.projectId,
          userId: this.$route.query.userId,
        },
      })
      .then((response) => (this.Detail = response.data));
  },
};
</script>

<style scoped>
.personalCenter-detail {
  max-width: 1240px;
  margin: 0 auto;
  min-height: 600px;
  padding: 40px 0 153px;
}

.personalCenter-detail .title {
  display: flex;
  margin-bottom: 24px;
}

.personalCenter-detail .title img {
  margin-right: 10px;
}

.personalCenter-detail .title h3 {
  color: rgba(30, 32, 36, 100);
  font-size: 24px;
  font-family: SourceHanSansSC-bold;
}

.personalCenter-detail .des {
  padding: 32px 0 32px 40px;
  background-color: rgba(247, 249, 255, 100);
}

.personalCenter-detail .des .button {
  margin-top: 40px;
}

.des .button span.begin {
  display: inline-block;
  margin-right: 24px;
  width: 94px;
  height: 36px;
  line-height: 36px;
  border-radius: 2px 2px 2px 2px;
  background-color: #06f;
  border: 1px solid rgba(0, 0, 0, 0.28);
  color: rgba(255, 255, 255, 100);
  font-size: 14px;
  text-align: center;
  font-family: Helvetica-regular;
  cursor: pointer;
}

.des .button span.delete {
  display: inline-block;
  margin-right: 24px;
  width: 94px;
  height: 36px;
  line-height: 36px;
  border-radius: 2px 2px 2px 2px;
  background-color: #06f;
  border: 1px solid rgba(0, 0, 0, 0.28);
  color: rgba(255, 255, 255, 100);
  font-size: 14px;
  text-align: center;
  font-family: Helvetica-regular;
  cursor: pointer;
}

.personalCenter-detail .des .per-content p {
  color: rgba(16, 16, 16, 100);
  font-size: 16px;
  font-family: SourceHanSansSC-regular;
  margin-bottom: 16px;
}
</style>
