<template>
  <Header></Header>
  <div class="personalCenter-detail" id="personalCenterTraining-detail">
    <div class="title">
      <img src="/img/fas_fa-folder-open.svg" alt="" />
      <h3>我的实训</h3>
    </div>
    <div class="des">
      <div class="per-content">
        <p>
          项目名称：<span>{{ Detail.traName }}</span>
        </p>
        <p>提交时间： {{ Detail.subTime }}</p>
        <p>
          当前得分：<span>{{ Detail.traScore }}</span>
        </p>
        <p>温馨提示：进入编程界面后,请勿修改文件名称，可能会造成文件丢失。</p>
      </div>
      <div class="button">
        <span @click="begin()" class="begin" v-loading.fullscreen="fullscreenLoading">开始编程</span>
        <span @click="resetWorkSpace()" class="reset-workSpace">重置工作区</span>
        <span @click="goHistory()" class="history">实训评价</span>
        <span @click="onlineSubmit(fileName)" class="onlineSubmit">在线提交</span>
      </div>
      <el-upload class="uploadCsv" action accept=".csv" :limit="1" :http-request="uploadCsv">
        <el-button type="success" class="submit">上传结果</el-button>
        <template #tip>
          <div class="el-upload__tip">
            若在线提交失败，可以把实训结果集下载到本地后上传，仅支持csv文件(当天同一文件多次上传取最后一次结果)
          </div>
        </template>
      </el-upload>
    </div>
    <div>
      <el-dialog title="实训评价" v-model="dialogTableVisible" center>
        <!-- 实训描述：实训题目 、 实训评价标准 -->
        <el-descriptions :column="1" border>
          <el-descriptions-item align="center">
            <template #label>
              <i class="el-icon-tickets"></i>
              实训题目
            </template>
            <el-tag>{{ Detail.traName }}</el-tag>
          </el-descriptions-item>
          <el-descriptions-item align="center">
            <template #label>
              <i class="el-icon-postcard"></i>
              评价标准
            </template>
            <el-tag v-for="(item, index) in criterion" :key="item">{{ index + 1 }}:{{ item }}</el-tag>
          </el-descriptions-item>
        </el-descriptions>
        <!-- 实训评价历史表 -->
        <el-table :data="historyScore" border stripe height="300"
          :default-sort="{ prop: 'subTime', order: 'descending' }">
          <el-table-column type="index" label="序号" width="80px" align="center"></el-table-column>
          <el-table-column property="subTime" label="提交时间" sortable align="center"></el-table-column>
          <!-- 暂时不用 -->
          <!-- <el-table-column
            property="traScore"
            label="得分"
            sortable
            align="center"
          ></el-table-column> -->
          <el-table-column label="操作" align="center">
            <template #default="scope">
              <el-button size="mini" @click="viewComments(scope.row)">评价详情</el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- 内部弹出层 -->
        <el-dialog width="30%" title="评价详情" v-model="innerVisible" append-to-body>
          <el-table :data="evaluationDetails" border stripe height="300">
            <el-table-column property="criterion" label="实训标准" align="center"></el-table-column>
            <el-table-column property="result" label="实训结果" align="center"></el-table-column>
            <!-- <el-table-column
              property="sysComment"
              label="系统评价"
            ></el-table-column> -->
          </el-table>
        </el-dialog>
      </el-dialog>
    </div>
  </div>
  <Footer></Footer>
</template>
<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import { ElMessage } from "element-plus";
export default {
  name: "PersonalCenterTrainingDetail",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      fullscreenLoading: false,
      userId: localStorage.getItem("userId"),
      jupyterhub: localStorage.getItem("jupyterhub"),
      Detail: "",
      criterion: "",
      historyScore: "",
      historyId: "",
      score: "",
      dialogTableVisible: false,
      innerVisible: false,
      evaluationDetails: "",
      sysEvaluationList: "",
      //在线提交默认文件名
      fileName: "result.csv",
    };
  },

  methods: {
    viewComments(row) {
      this.axios
        .get("/training/evaluationDetails", {
          params: {
            historyId: row.id,
          },
        })
        .then((response) => (this.evaluationDetails = response.data));
      this.innerVisible = true;
    },
    onlineSubmit(fileName) {
      this.fullscreenLoading = true;
      this.axios
        .get("/training/onlineSubmit", {
          params: {
            userId: this.userId,
            traId: this.$route.query.trainingId,
            fileName: fileName,
          },
        })
        .then((response) => {
          this.fullscreenLoading = false;
          if (response.data.code == 400) {
            ElMessage.error({
              message: response.data.msg,
            });
          }
          if (response.data.code == 404) {
            ElMessage.error({
              message: response.data.msg,
            });
          }
          if (response.data.code == 200) {
            ElMessage.success({
              message: "评分成功,即将刷新页面",
            });
            //刷新页面
            setTimeout(function () {
              location.reload();
            }, 1000);
          }
        })
        .catch((error) => {
          this.fullscreenLoading = false;
          ElMessage.error({
            message: "服务器发生未知错误,本次评价失败",
          });
        });
    },

    uploadCsv(fileObj) {
      let formData = new FormData();
      formData.set("username", localStorage.getItem("username"));
      formData.set("traName", this.Detail.traName);
      formData.set("file", fileObj.file);
      this.axios
        .post("/uploadCsv", formData, {
          headers: {
            "Content-type": "multipart/form-data",
          },
        })
        .then((response) => {
          ElMessage.success({
            message: response.data.msg,
            type: "success",
          });
          if (response.data.code == 200) {
            this.onlineSubmit(response.data.fileName);
          }
        });
    },

    begin() {
      this.fullscreenLoading = true;
      // 复制模板
      this.axios
        .get("/training/cpTraining", {
          params: {
            userId: this.userId,
            traId: this.Detail.traId,
            username: localStorage.getItem("username"),
            traName: this.Detail.traName,
          },
        })
        .then((response) => {
          if (response.data.msg == "准备就绪") {
            window.open(
              "http://jupyter.ditwin.cn:8000/hub/login?token=" +
              this.jupyterhub +
              "&next=/user/" +
              localStorage.getItem("username") +
              "/notebooks/实训项目/" +
              this.Detail.traName +
              "/" +
              this.Detail.traName +
              ".ipynb"
            );
            this.fullscreenLoading = false;
          }
        });
    },
    resetWorkSpace() {
      this.axios
        .get("/training/resetWorkSpace", {
          params: {
            username: localStorage.getItem("username"),
            traName: this.Detail.traName
          }
        })
        .then(() => {
          ElMessage.success({
            message: "工作区重置成功",
            type: "success",
          });
        })
    },
    goHistory() {
      this.axios
        .get("/training/selectHistoryScore", {
          params: {
            userId: this.userId,
            traId: this.Detail.traId,
          },
        })
        .then((response) => (this.historyScore = response.data));
      this.dialogTableVisible = true;
    },
  },

  created() {
    this.axios
      .get("/training/selectTraScore", {
        params: {
          userId: this.$route.query.userId,
          traId: this.$route.query.trainingId,
        },
      })
      .then((response) => {
        this.Detail = response.data;
      });
    //读取当前实训的评价标准并处理
    this.axios
      .get("/training/selectTraCriterion", {
        params: { traId: this.$route.query.trainingId },
      })
      .then((response) => (this.criterion = response.data));
  },
};
</script>

<style scoped>
#personalCenterTraining-detail /deep/.el-tag {
  margin: 0 10px;
}

.uploadCsv {
  margin: 10px 0px;
}

.personalCenter-detail {
  max-width: 1240px;
  margin: 0 auto;
  min-height: 600px;
  padding: 40px 0 153px;
}

.personalCenter-detail .title {
  display: flex;
  margin-bottom: 24px;
}

.personalCenter-detail .title img {
  margin-right: 10px;
}

.personalCenter-detail .title h3 {
  color: rgba(30, 32, 36, 100);
  font-size: 24px;
  font-family: SourceHanSansSC-bold;
}

.personalCenter-detail .des {
  padding: 32px 0 32px 40px;
  background-color: rgba(247, 249, 255, 100);
}

.personalCenter-detail .des .button {
  margin-top: 40px;
}

.des .button span.begin {
  display: inline-block;
  margin-right: 24px;
  width: 94px;
  height: 36px;
  line-height: 36px;
  border-radius: 2px 2px 2px 2px;
  background-color: #06f;
  border: 1px solid rgba(0, 0, 0, 0.28);
  color: rgba(255, 255, 255, 100);
  font-size: 14px;
  text-align: center;
  font-family: Helvetica-regular;
  cursor: pointer;
}

.des .button span.upload {
  display: inline-block;
  margin-right: 24px;
  width: 94px;
  height: 36px;
  line-height: 36px;
  border-radius: 2px 2px 2px 2px;
  background-color: #06f;
  border: 1px solid rgba(0, 0, 0, 0.28);
  color: rgba(255, 255, 255, 100);
  font-size: 14px;
  text-align: center;
  font-family: Helvetica-regular;
  cursor: pointer;
}

.des .button span.submit {
  display: inline-block;
  margin-right: 24px;
  width: 94px;
  height: 36px;
  line-height: 36px;
  border-radius: 2px 2px 2px 2px;
  background-color: #06f;
  border: 1px solid rgba(0, 0, 0, 0.28);
  color: rgba(255, 255, 255, 100);
  font-size: 14px;
  text-align: center;
  font-family: Helvetica-regular;
  cursor: pointer;
}

.des .button span.reset-workSpace {
  display: inline-block;
  margin-right: 24px;
  width: 94px;
  height: 36px;
  line-height: 36px;
  border-radius: 2px 2px 2px 2px;
  background-color: #06f;
  border: 1px solid rgba(0, 0, 0, 0.28);
  color: rgba(255, 255, 255, 100);
  font-size: 14px;
  text-align: center;
  font-family: Helvetica-regular;
  cursor: pointer;
}

.des .button span.history {
  display: inline-block;
  margin-right: 24px;
  width: 94px;
  height: 36px;
  line-height: 36px;
  border-radius: 2px 2px 2px 2px;
  background-color: #06f;
  border: 1px solid rgba(0, 0, 0, 0.28);
  color: rgba(255, 255, 255, 100);
  font-size: 14px;
  text-align: center;
  font-family: Helvetica-regular;
  cursor: pointer;
}

.des .button span.onlineSubmit {
  display: inline-block;
  margin-right: 24px;
  width: 94px;
  height: 36px;
  line-height: 36px;
  border-radius: 2px 2px 2px 2px;
  background-color: #06f;
  border: 1px solid rgba(0, 0, 0, 0.28);
  color: rgba(255, 255, 255, 100);
  font-size: 14px;
  text-align: center;
  font-family: Helvetica-regular;
  cursor: pointer;
}

.personalCenter-detail .des .per-content p {
  color: rgba(16, 16, 16, 100);
  font-size: 16px;
  font-family: SourceHanSansSC-regular;
  margin-bottom: 16px;
}
</style>
