<template>
  <Header></Header>
  <div class="dynamic-list">
    <h3>动态专区</h3>
    <div class="item" v-for="item in dynamicList" :key="item.id">
      <router-link
        :to="{ path: '/dynamicdetail', query: { dynamicId: item.id } }"
      >
        <span class="type">{{ item.dyType }}</span>
        <span class="title">{{ item.dyTitle }}</span>
        <span class="time">{{ item.createTime }}</span>
      </router-link>
    </div>
  </div>
  <Footer></Footer>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
export default {
  name: "Dynamic",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      dynamicList: "",
    };
  },
  created() {
    this.axios
      .get("/dynamic/selectAllDynamic")
      .then((response) => (this.dynamicList = response.data));
  },
};
</script>

<style scoped>
/* dynamic */

.dynamic-list {
  max-width: 1240px;
  margin: 0 auto;
  min-height: 600px;
  padding: 32px 0 90px;
}

.dynamic-list h3 {
  color: rgba(16, 16, 16, 100);
  font-size: 24px;
  text-align: left;
  font-family: Roboto;
  margin-bottom: 44px;
}

.dynamic-list .item {
  margin-bottom: 22px;
}

.dynamic-list .item a {
  display: flex;
  align-items: center;
}

.dynamic-list .item span.type {
  width: 58px;
  height: 32px;
  line-height: 32px;
  border-radius: 10px;
  background-color: rgba(247, 249, 255, 100);
  color: rgba(26, 128, 255, 100);
  font-size: 14px;
  text-align: center;
  font-family: Roboto;
  border: 1px solid rgba(255, 255, 255, 100);
}

.dynamic-list .item span.title {
  display: inline-block;
  width: 500px;
  margin: 0 16px 0 24px;
  color: #333;
  font-size: 16px;
  font-family: SourceHanSansSC-regular;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dynamic-list .item span.time {
  color: rgb(30 32 36 / 50%);
  font-size: 12px;
  font-family: SourceHanSansSC-regular;
}
</style>
