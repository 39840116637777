<template>
  <Header></Header>
  <div id="ExpReviewDetail">
    <div class="ExpReviewDetail-content">
      <h2>{{ exp_evalution_detail_name }}</h2>
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="标准一 : ">
          <el-input
            type="textarea"
            v-model="exp_evalution_detail.criterionOne"
          ></el-input>
        </el-form-item>
        <el-form-item label="标准二 : ">
          <el-input
            type="textarea"
            v-model="exp_evalution_detail.criterionTwo"
          ></el-input>
        </el-form-item>
        <el-form-item label="标准三 : ">
          <el-input
            type="textarea"
            v-model="exp_evalution_detail.criterionThree"
          ></el-input>
        </el-form-item>
        <el-form-item label="标准四 : ">
          <el-input
            type="textarea"
            v-model="exp_evalution_detail.criterionFour"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
  </div>
  <Footer></Footer>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
export default {
  name: "ExpReviewDetail",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      exp_evalution_id: "",
      exp_evalution_detail: "",
    };
  },
  created() {
    this.exp_evalution_id = this.$route.query.exp_evalution_id;
    this.axios
      .get("/expEvaluation/selectOne", {
        params: {
          id: this.exp_evalution_id,
        },
      })
      .then((response) => (this.exp_evalution_detail = response.data));
  },
};
</script>

<style scoped>
.ExpReviewDetail-content {
  max-width: 1240px;
  margin: 0 auto;
  min-height: 600px;
  padding: 35px 0 88px;
}
</style>