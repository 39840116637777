<template>
  <Header></Header>
  <div id="case">
    <div class="case-banner">
      <img src="@/assets/img/case-banner.png" alt="" />
    </div>
    <div class="case-content">
      <div class="case-analysis">
        <div class="button">
          <img src="@/assets/img/fas_fa-folder-open.svg" alt="" />
          <span>全部案例</span>
        </div>
        <div class="case-list">
          <el-tabs v-model="activeName">
            <el-tab-pane label="全部案例" name="first">
              <div
                class="case-item"
                v-for="item in allProject"
                :key="item.projectId"
              >
                <router-link
                  :to="{
                    path: 'casedetail',
                    query: {
                      projectId: item.projectId,
                      title: item.projectName,
                    },
                  }"
                >
                  <div class="case-item-left">
                    <div class="case-item-title">
                      <h3>{{ item.projectName }}</h3>
                      <span class="time">{{ item.createTime }}</span>
                    </div>
                    <div class="tubiao">
                      <div class="type">
                        <img src="@/assets/img/kuo.png" alt="" />
                        <span>{{ item.programing }}</span>
                      </div>
                      <div class="share">
                        <img src="@/assets/img/share.png" alt="" />
                        <span>{{ item.share }}</span>
                      </div>
                      <div class="views">
                        <img src="@/assets/img/view.png" alt="" />
                        <span>{{ item.views }}</span>
                      </div>
                      <div class="liuy">
                        <img src="@/assets/img/message.png" alt="" />
                        <span>{{ item.comments }}</span>
                      </div>
                    </div>
                    <div class="tag">
                      <p>
                        <span>{{ item.projectTag }}</span>
                      </p>
                    </div>
                  </div>
                  <div class="case-item-right">
                    <img src="@/assets/img/dianzan.png" alt="" />
                    <span>{{ item.thumbsUp }}</span>
                  </div>
                </router-link>
              </div>
            </el-tab-pane>
            <el-tab-pane label="推荐案例" name="second">
              <div
                class="case-item"
                v-for="item in comProject"
                :key="item.projectId"
              >
                <router-link
                  :to="{
                    path: 'casedetail',
                    query: {
                      projectId: item.projectId,
                      title: item.projectName,
                    },
                  }"
                >
                  <div class="case-item-left">
                    <div class="case-item-title">
                      <h3>{{ item.projectName }}</h3>
                      <span class="time">{{ item.createTime }}</span>
                    </div>
                    <div class="tubiao">
                      <div class="type">
                        <img src="@/assets/img/kuo.png" alt="" />
                        <span>{{ item.programing }}</span>
                      </div>
                      <div class="share">
                        <img src="@/assets/img/share.png" alt="" />
                        <span>{{ item.share }}</span>
                      </div>
                      <div class="views">
                        <img src="@/assets/img/view.png" alt="" />
                        <span>{{ item.views }}</span>
                      </div>
                      <div class="liuy">
                        <img src="@/assets/img/message.png" alt="" />
                        <span>{{ item.comments }}</span>
                      </div>
                    </div>
                    <div class="tag">
                      <p>
                        <span>{{ item.projectTag }}</span>
                      </p>
                    </div>
                  </div>
                  <div class="case-item-right">
                    <img src="@/assets/img/dianzan.png" alt="" />
                    <span>{{ item.thumbsUp }}</span>
                  </div>
                </router-link>
              </div>
            </el-tab-pane>
            <el-tab-pane label="热门案例" name="third">
              <div class="case-item" v-for="item in hotProject" :key="item.id">
                <router-link
                  :to="{
                    path: 'casedetail',
                    query: {
                      projectId: item.projectId,
                      title: item.projectName,
                    },
                  }"
                >
                  <div class="case-item-left">
                    <div class="case-item-title">
                      <h3>{{ item.projectName }}</h3>
                      <span class="time">{{ item.createTime }}</span>
                    </div>
                    <div class="tubiao">
                      <div class="type">
                        <img src="@/assets/img/kuo.png" alt="" />
                        <span>{{ item.programing }}</span>
                      </div>
                      <div class="share">
                        <img src="@/assets/img/share.png" alt="" />
                        <span>{{ item.share }}</span>
                      </div>
                      <div class="views">
                        <img src="@/assets/img/view.png" alt="" />
                        <span>{{ item.views }}</span>
                      </div>
                      <div class="liuy">
                        <img src="@/assets/img/message.png" alt="" />
                        <span>{{ item.comments }}</span>
                      </div>
                    </div>
                    <div class="tag">
                      <p>
                        <span>{{ item.projectTag }}</span>
                      </p>
                    </div>
                  </div>
                  <div class="case-item-right">
                    <img src="@/assets/img/dianzan.png" alt="" />
                    <span>{{ item.thumbsUp }}</span>
                  </div>
                </router-link>
              </div>
            </el-tab-pane>
            <el-tab-pane label="新手入门" name="fourth">
              <div
                class="case-item"
                v-for="item in priProject"
                :key="item.projectId"
              >
                <router-link
                  :to="{
                    path: 'casedetail',
                    query: {
                      projectId: item.projectId,
                      title: item.projectName,
                    },
                  }"
                >
                  <div class="case-item-left">
                    <div class="case-item-title">
                      <h3>{{ item.projectName }}</h3>
                      <span class="time">{{ item.createTime }}</span>
                    </div>
                    <div class="tubiao">
                      <div class="type">
                        <img src="@/assets/img/kuo.png" alt="" />
                        <span>{{ item.programing }}</span>
                      </div>
                      <div class="share">
                        <img src="@/assets/img/share.png" alt="" />
                        <span>{{ item.share }}</span>
                      </div>
                      <div class="views">
                        <img src="@/assets/img/view.png" alt="" />
                        <span>{{ item.views }}</span>
                      </div>
                      <div class="liuy">
                        <img src="@/assets/img/message.png" alt="" />
                        <span>{{ item.comments }}</span>
                      </div>
                    </div>
                    <div class="tag">
                      <p>
                        <span>{{ item.projectTag }}</span>
                      </p>
                    </div>
                  </div>
                  <div class="case-item-right">
                    <img src="@/assets/img/dianzan.png" alt="" />
                    <span>{{ item.thumbsUp }}</span>
                  </div>
                </router-link>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
      <div class="case-recom">
        <div class="button">
          <span>系统推荐</span>
        </div>
        <ul v-for="item in sysComProject" :key="item.projectId">
          <li>
            <router-link
              :to="{
                path: 'casedetail',
                query: { projectId: item.projectId, title: item.projectName },
              }"
            >
              {{ item.projectName }}
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <Footer></Footer>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
export default {
  name: "Case",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      activeName: "first",
      sysComProject: "",
      allProject: "",
      comProject: "",
      hotProject: "",
      priProject: "",
    };
  },

  created() {
    this.axios
      .get("/project/selectProjectByType", {
        params: {
          userId: localStorage.getItem("userId"),
          type: 1,
        },
      })
      .then((response) => (this.comProject = response.data));

    this.axios
      .get("/project/selectProjectByType", {
        params: {
          userId: localStorage.getItem("userId"),
          type: 2,
        },
      })
      .then((response) => (this.hotProject = response.data));

    this.axios
      .get("/project/selectProjectByType", {
        params: {
          userId: localStorage.getItem("userId"),
          type: 3,
        },
      })
      .then((response) => (this.priProject = response.data));

    this.axios
      .get("/project/selectProjectByType", {
        params: {
          userId: localStorage.getItem("userId"),
          type: 4,
        },
      })
      .then((response) => (this.sysComProject = response.data));

    this.axios
      .get("/project/selectAllProject", {
        params: {
          userId: localStorage.getItem("userId"),
        },
      })
      .then((response) => (this.allProject = response.data));
  },
};
</script>

<style scoped>
#case /deep/ .el-tabs__item {
  padding: 0 60px 0 0;
  height: 45px;
  font-size: 20px;
  font-weight: 540;
}

#case /deep/ .el-tabs__active-bar {
  height: 3px;
}

#case /deep/ .el-tabs__item.is-active {
  font-weight: bold;
  color: #1a80ff;
}

#case /deep/ .el-tabs__nav-wrap::after {
  height: 2px;
  background-color: #1a80ff;
}

.case-list {
  margin: 30px 0 20px;
}

/* case */

.case-banner {
  text-align: center;
}

.case-banner img {
  width: 100%;
}

.case-content {
  max-width: 1270px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding-top: 42px;
  padding-bottom: 76px;
}

.case-item h3 {
  color: rgba(16, 16, 16, 100);
  font-size: 18px;
  text-align: center;
  font-family: Roboto;
  display: inline-block;
  margin-right: 15px;
}

.case-item span {
  color: rgba(16, 16, 16, 0.5);
  font-size: 14px;
}

.case-item .tubiao {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.case-item .tubiao .toux {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.tubiao img {
  width: 15px;
}

.case-item .tubiao .toux img {
  width: 20px;
}

.case-item span.subtitle {
  display: block;
  margin: 16px 0;
}

.case-item span.name {
  display: inline-block;
  margin: 0 8px;
}

.case-item span.level {
  display: inline-block;
  width: 40px;
  height: 20px;
  background: rgba(26, 128, 255, 0.2);
  border-radius: 40%;
  color: rgba(26, 128, 255, 100);
  text-align: center;
}

.case-item .type,
.case-item .share,
.case-item .views,
.case-item .liuy {
  margin-right: 15px;
}

.share,
.views,
.liuy,
.shuom,
.type {
  display: flex;
  align-items: center;
}

.share img,
.views img,
.liuy img,
.shuom img {
  margin-right: 8px;
}

.type img {
  width: 30px;
}

.case-item {
  margin: 30px 0 60px;
}

.case-item .tag span {
  height: 20px;
  background: hsl(219deg 84% 38% / 10%);
  display: inline-block;
  margin-right: 10px;
  border-radius: 30%;
  text-align: center;
  padding: 0 8px;
  color: #2354af;
}

.case-item a {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.case-item-right {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 65px;
  height: 30px;
  border: #cac5c5 1px solid;
  border-radius: 35px;
}

.case-item-right img {
  width: 15px;
}

.case-analysis {
  width: 800px;
}

.case-content .button {
  color: rgba(30, 32, 36, 100);
  font-size: 24px;
  font-family: SourceHanSansSC-bold;
}

.case-content .button img,
.case-content .button span {
  vertical-align: middle;
}

.case-content .button img {
  margin-right: 12px;
}

.case-content .button span {
  font-weight: 600;
}

.case-img {
  margin-left: -30px;
}

.case-recom .button {
  margin-bottom: 6px;
}

.case-recom ul li {
  line-height: 60px;
  border-bottom: 1px solid #c1c1c1;
  position: relative;
  padding-left: 24px;
}

.case-recom ul li::before {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  line-height: 20px;
  background-color: rgba(26, 128, 255, 100);
  border: 1px solid rgba(255, 255, 255, 100);
  border-radius: 100%;
  top: 25px;
  left: 0;
}

.case-recom ul li a {
  color: #000;
  font-size: 20px;
  text-align: left;
  font-family: Microsoft Yahei;
}
</style>
