<template>
  <div class="login" id="login">
    <el-form
      :model="loginForm"
      :rules="rules"
      ref="loginForm"
      class="login-Form"
    >
      <div class="title">
        <h3>数据科学平台</h3>
        <router-link to="/register" class="list-item">
          <el-link type="primary">没有帐号？ 点此注册</el-link>
        </router-link>
      </div>
      <el-form-item prop="username">
        <el-input
          type="text"
          v-model="loginForm.username"
          prefix-icon="el-icon-user-solid"
        ></el-input>
      </el-form-item>
      <el-form-item prop="password">
        <el-input
          type="password"
          v-model="loginForm.password"
          prefix-icon="el-icon-lock"
          @keyup.enter="submitForm('loginForm')"
        ></el-input>
      </el-form-item>
      <el-form-item prop="verifyCode">
        <el-input
          type="text"
          v-model="loginForm.verifyCode"
          autocomplete="off"
          placeholder="验证码"
          style="width: 63%"
          prefix-icon="el-icon-message"
          @keyup.enter="submitForm('loginForm')"
        ></el-input>
        <div class="login-code">
          <img :src="codeUrl" @click="getCode" class="login-code-img" />
        </div>
      </el-form-item>
      <el-button
        type="primary"
        @click="submitForm('loginForm')"
        style="width: 100%"
        >登录</el-button
      >
    </el-form>
  </div>
</template>

<script>
import qs from "qs";
import { ElMessage } from "element-plus";
export default {
  name: "login",
  data() {
    return {
      codeUrl: "",
      codeResult: "",
      loginForm: {
        username: "",
        password: "",
        verifyCode: "",
      },
      rules: {
        username: [
          {
            required: true,
            message: "请输入用户名",
            trigger: "blur",
          },
          {
            pattern: /^[a-zA-Z0-9_]{3,16}$/,
            message: "请输入3到16位（字母，数字，下划线）的用户名",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
          {
            pattern: /^[a-zA-Z0-9@._-]{3,16}$/,
            message: "请输入3到16位[字母，数字，符号（@._-）]的密码",
            trigger: "blur",
          },
        ],
        verifyCode: [
          {
            required: true,
            message: "请输入验证码",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    getCode() {
      this.axios
        .get("/kaptcha", {
          params: {
            time: new Date(),
          },
        })
        .then((response) => {
          this.codeUrl = response.data.url;
          this.codeResult = response.data.result;
        });
    },
    submitForm(loginForm) {
      this.$refs[loginForm].validate((valid) => {
        if (valid) {
          //验证通过 首先校验验证码是否正确
          if (this.loginForm.verifyCode != this.codeResult) {
            ElMessage.error("验证码错误!");
            this.getCode();
            return;
          }
          //验证码正确 再执行登录
          this.doLogin();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    doLogin() {
      this.axios({
        url: "/user/login",
        method: "post",
        data: qs.stringify(this.loginForm),
      }).then((response) => {
        if (response.data.msg == "success") {
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("jupyterhub", response.data.jupyterhub);
          localStorage.setItem("username", response.data.username);
          localStorage.setItem("userId", response.data.userId);
          //设置企业名
          if (response.data.enterpriseName != null) {
            localStorage.setItem(
              "enterpriseName",
              response.data.enterpriseName
            );
          }
          if (response.data.realname != null) {
            localStorage.setItem("realname", response.data.realname);
          }
          this.$router.push({ path: "/home" });
        } else {
          ElMessage.error(response.data.msg);
          this.getCode();
        }
      });
    },
  },
  created() {
    this.getCode();
  },
};
</script>

<style scoped>
.login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-image: url(/Background.jpg);
  background-size: cover;
}

.login-Form {
  border-radius: 6px;
  background: #fff;
  width: 400px;
  padding: 25px 25px 30px 25px;
}

.title {
  margin: 0 auto 30px auto;
  /* text-align: center; */
  color: #707070;
}

#login /deep/ .el-form-item__label {
  text-align: left;
}

.login-code {
  width: 33%;
  height: 38px;
  float: right;
}

.login-code .img {
  cursor: pointer;
  vertical-align: middle;
}

.login-code-img {
  height: 38px;
  width: 120px;
}
</style>
