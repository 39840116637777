<template>
  <Header></Header>
  <div id="DynamicDetail">
    <div class="DynamicDetail-content">
      <h3>{{ dynamicDetail.dyTitle }}</h3>
      <span>发布时间 : {{ dynamicDetail.createTime }}</span>
      <span>阅读人数 : {{ dynamicDetail.dyReadNum }}</span>
      <br />
      <br />
      <div class="markdown-body" v-html="DynamicMarkDown"></div>
    </div>
  </div>
  <Footer></Footer>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import MarkDownIt from "markdown-it";
import "github-markdown-css";
export default {
  name: "DynamicDetail",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      dynamicId: "",
      dynamicDetail: "",
    };
  },
  computed: {
    DynamicMarkDown() {
      //初始化时候this.dynamicDetail.dyContent不存在 页面会报错
      if (this.dynamicDetail.dyContent != null) {
        const md = new MarkDownIt();
        const result = md.render(this.dynamicDetail.dyContent);
        return result;
      }
    },
  },
  created() {
    this.dynamicId = this.$route.query.dynamicId;
    this.axios
      .get("/dynamic/selectOne", {
        params: {
          id: this.dynamicId,
        },
      })
      .then((response) => (this.dynamicDetail = response.data));
  },
};
</script>

<style scoped>
/* 文本内容显示正常CSS */
.DynamicDetail-content .description {
  white-space: pre-line;
  margin: 20px 0;
  padding: 10px 0;
}

.DynamicDetail-content {
  max-width: 1240px;
  margin: 0 auto;
  min-height: 600px;
  padding: 35px 0 88px;
}
.DynamicDetail-content span {
  color: rgba(16, 16, 16, 100);
  font-size: 14px;
  font-family: Roboto;
  margin: 0 10px;
}

.DynamicDetail-content h3 {
  color: rgba(0, 0, 0, 1);
  font-size: 24px;
  font-family: Roboto;
  margin: 24px 0 10px;
}

img.views {
  vertical-align: middle;
  margin-left: 24px;
}

.DynamicDetail-content .description p {
  color: rgba(16, 16, 16, 100);
  font-size: 16px;
  line-height: 28px;
  font-family: SourceHanSansSC-regular;
  margin-top: 40px;
}
</style>