<template>
  <Header></Header>
  <div id="person">
    <div class="gowork">
      <el-button type="primary" @click="LoginOut">退出</el-button>
      <el-button type="primary" @click="realnameDialog = true"
        >实名认证</el-button
      >
      <a :href="url" target="_blank"
        ><el-button type="primary">进入工作区</el-button>
      </a>
    </div>
    <div class="person-content">
      <div class="person-info-box">
        <el-tabs v-model="activeName">
          <el-tab-pane label="个人信息" name="first">
            <MyInfo></MyInfo>
          </el-tab-pane>
          <el-tab-pane label="我的案例" name="second">
            <div class="person-con">
              <div class="person-info">
                <div
                  class="person-item"
                  v-for="item in mycaseList"
                  :key="item.projectId"
                >
                  <router-link
                    :to="{
                      path: '/personalCenterProjectDetail',
                      query: { userId: userId, projectId: item.projectId },
                    }"
                  >
                    <div class="person-item-img">
                      <img
                        v-if="item.enterpriseName == null"
                        src="/img/item-logo/default.svg"
                        alt=""
                      />
                      <img v-else :src="projectLogo" alt="" />
                    </div>
                    <div class="person-item-text">
                      <h3>{{ item.projectName }}</h3>
                      <span>{{ item.createTime }}</span>
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="我的实训" name="third">
            <div class="person-con">
              <div class="person-info">
                <div
                  class="person-item"
                  v-for="item in mytrainList"
                  :key="item.traId"
                >
                  <router-link
                    :to="{
                      path: '/personalCenterTrainingDetail',
                      query: { userId: userId, trainingId: item.traId },
                    }"
                  >
                    <div class="person-item-img">
                      <img
                        v-if="item.enterpriseName == null"
                        src="/img/item-logo/default.svg"
                        alt=""
                      />
                      <img v-else :src="trainLogo" alt="" />
                    </div>
                    <div class="person-item-text">
                      <h3>{{ item.traName }}</h3>
                      <span>{{ item.createTime }}</span>
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
  <el-dialog title="实名认证" v-model="realnameDialog" width="30%">
    <el-form
      :model="realnameForm"
      status-icon
      :rules="rules"
      ref="realnameForm"
      label-width="100px"
    >
      <el-form-item label="姓名" prop="realname">
        <el-input
          type="text"
          v-model="realnameForm.realname"
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <el-form-item label="确认姓名" prop="checkRealname">
        <el-input
          type="text"
          v-model="realnameForm.checkRealname"
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="resetForm('realnameForm')">重置</el-button>
        <el-button type="primary" @click="changeInfo('realnameForm')"
          >提交</el-button
        >
      </el-form-item>
    </el-form>
  </el-dialog>
  <Footer></Footer>
</template>


<script>
import { ElMessage } from "element-plus";
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import MyInfo from "@/views/PersonalCenter/MyInfo";
export default {
  components: { MyInfo, Header, Footer },
  name: "PersonalCenter",
  data() {
    var validateRealname = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入姓名"));
      } else if (value !== this.realnameForm.realname) {
        callback(new Error("两次输入不一致!"));
      } else {
        callback();
      }
    };
    return {
      url:
        "http://jupyter.ditwin.cn:8000/hub/login?token=" +
        localStorage.getItem("jupyterhub") +
        "&next=/user/" +
        localStorage.getItem("username"),
      activeName: "first",
      realnameDialog: false,
      userId: localStorage.getItem("userId"),
      mycaseList: "",
      mytrainList: "",
      projectLogo:
        "/img/item-logo/" + localStorage.getItem("enterpriseName") + ".svg",
      trainLogo:
        "/img/item-logo/" + localStorage.getItem("enterpriseName") + ".svg",
      realnameForm: {
        realname: "",
        checkRealname: "",
      },
      rules: {
        realname: [
          {
            required: true,
            message: "请输入姓名",
            trigger: "blur",
          },
          {
            pattern: /^(?:[\u4e00-\u9fa5·]{2,16})$/,
            message: "请输入真实姓名",
            trigger: "blur",
          },
        ],
        checkRealname: [
          {
            validator: validateRealname,
            trigger: "blur",
          },
        ],
      },
    };
  },

  created() {
    this.axios
      .get("/project/selectProjectByUserId", {
        params: {
          userId: this.userId,
        },
      })
      .then((response) => (this.mycaseList = response.data));
    this.axios
      .get("/training/selectTrainingByUserId", {
        params: {
          userId: this.userId,
        },
      })
      .then((response) => (this.mytrainList = response.data));
  },

  methods: {
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    changeInfo(realnameForm) {
      var REALNAME = this.realnameForm.realname;
      this.$refs[realnameForm].validate((valid) => {
        if (valid) {
          this.axios
            .get("/user/addRealname", {
              params: {
                userId: this.userId,
                realname: this.realnameForm.realname,
              },
            })
            .then((response) => {
              var data = response.data;
              if (data.code == 200) {
                ElMessage({
                  showClose: true,
                  message: data.message,
                  type: "success",
                });
                localStorage.setItem("realname", REALNAME);
              } else {
                ElMessage({
                  showClose: true,
                  message: data.message,
                  type: "error",
                });
              }
            });
          this.realnameDialog = false;
          this.$refs[realnameForm].resetFields();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    LoginOut() {
      if (localStorage.getItem("token") != null) {
        this.$confirm("确认要退出登录吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            localStorage.clear();

            this.$message({
              type: "success",
              message: "退出登录成功!",
            });
            this.$router.push({ path: "/login" });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            });
          });
      } else {
        ElMessage.error("请先登录哦");
      }
    },
  },
};
</script>

<style scoped>
#person /deep/.el-tabs__item {
  padding: 0 60px 0 0;
  height: 45px;
  font-size: 20px;
  font-weight: 540;
}

#person /deep/ .el-button--primary {
  color: #fff;
  background-color: #06f;
  border-color: #409eff;
  margin-right: 20px;
}

#person /deep/.el-tabs__active-bar {
  height: 3px;
}

#person /deep/.el-tabs__item.is-active {
  font-weight: bold;
  color: #1a80ff;
}

#person /deep/.el-tabs__nav-wrap::after {
  height: 2px;
  background-color: #1a80ff;
}

.gowork {
  text-decoration: none;
  display: flex;
  flex-direction: row-reverse;
  margin: 10px 90px 0px 0px;
}
/* personalCenter */

.personalCenter {
  max-width: 1240px;
  margin: 0 auto;
  min-height: 600px;
  padding: 40px 0 64px;
}

.personalCenter .content {
  display: flex;
  flex-wrap: wrap;
}

/* personalCenter */

.person-content {
  max-width: 1240px;
  margin: 0 auto;
  min-height: 600px;
}

.person-info {
  display: flex;
  flex-wrap: wrap;
}

.person-info-box .person-con:first-child {
  display: block;
}

.person-item {
  width: 346px;
  height: 120px;
  margin-top: 40px;
  margin-right: 98px;
  border: 1px solid rgba(187, 187, 187, 100);
}

.person-item a {
  display: flex;
  align-items: center;
}

.person-item:nth-child(3n) {
  margin-right: 0;
}

.person-item-img {
  width: 100px;
  height: 120px;
  border-right: 1px solid rgba(187, 187, 187, 100);
  background-color: rgba(241, 248, 255, 100);
  position: relative;
}

.person-item-img img {
  height: 40%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.person-item-text {
  width: 226px;
  padding-left: 16px;
}

.person-item-text h3 {
  color: rgba(16, 16, 16, 100);
  font-size: 18px;
  font-family: Roboto-bold;
  margin-bottom: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.person-item-text span {
  color: rgba(75, 77, 83, 100);
  font-size: 14px;
  font-family: Roboto;
}
</style>
