import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '@/views/Home'
import Case from '@/views/Case/Case'
import CaseDetail from '@/views/Case/CaseDetail'
import Training from '@/views/Training/Training'
import TrainingDetail from '@/views/Training/TrainingDetail'
import PersonalCenter from '@/views/PersonalCenter/PersonalCenter'
import MyInfo from '@/views/PersonalCenter/MyInfo'
import PersonalCenterProjectDetail from '@/views/PersonalCenter/PersonalCenterProjectDetail'
import PersonalCenterTrainingDetail from '@/views/PersonalCenter/PersonalCenterTrainingDetail'
import Review from '@/views/Review/Review'
import SysReviewDetail from '@/views/Review/SysReviewDetail'
import ExpReviewDetail from '@/views/Review/ExpReviewDetail'
import Dynamic from '@/views/Dynamic/Dynamic'
import DynamicDetail from '@/views/Dynamic/DynamicDetail'
import Login from '@/views/Login'
import Register from '@/views/Register'
import Help from '@/views/Help'

const routes = [{
        path: '/',
        component: Home
    },
    {
        path: '/home',
        component: Home
    },
    {
        path: '/case',
        component: Case
    },
    {
        path: '/casedetail',
        name: 'casedetail',
        component: CaseDetail
    },
    {
        path: '/training',
        component: Training
    },
    {
        path: '/traningdetail',
        name: 'traningdetail',
        component: TrainingDetail
    },
    {
        path: '/review',
        component: Review
    },
    {
        path: '/sysreviewdetail',
        name: 'sysReviewDetail',
        component: SysReviewDetail
    },
    {
        path: '/expreviewdetail',
        name: 'expReviewDetail',
        component: ExpReviewDetail
    },
    {
        path: '/dynamic',
        component: Dynamic
    },
    {
        path: '/dynamicdetail',
        name: 'dynamicDetail',
        component: DynamicDetail
    },
    {
        path: '/personalCenter',
        component: PersonalCenter
    },
    {
        path: '/personalCenterTrainingDetail',
        component: PersonalCenterTrainingDetail
    },
    {
        path: '/personalCenterProjectDetail',
        component: PersonalCenterProjectDetail
    },
    {
        path: '/login',
        component: Login
    },
    {
        path: '/register',
        component: Register
    },
    {
        path: '/myinfo',
        component: MyInfo
    }, {
        path: '/help',
        component: Help
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

// 每次打开页面默认定位到顶部
router.beforeEach((to, from, next) => {
    // chrome
    document.body.scrollTop = 0
        // firefox
    document.documentElement.scrollTop = 0
        // safari
    window.pageYOffset = 0
    next()
})

/*路由守卫   根据登录获得token*/

router.beforeEach((to, from, next) => {

    const isLogin = localStorage.token ? true : false;
    //放行页面
    if (to.path === "/login" || to.path === "/register") {

        next();

    } else {

        isLogin ? next() : next("/login") /*真跳转  假登录*/

    }

})

export default router