<template>
  <div class="header">
    <div class="head-show">
      <div class="head-title">
        <div>
          <router-link to="/Home" class="list-item">
            <img v-if="enterpriseName != null" :src="mainLogo" class="logo" />
            <img v-else :src="defaultLogo" alt="" class="logo" />
          </router-link>
        </div>
        <div>
          <span v-if="enterpriseName != null" class="main-title">{{
            enterpriseName
          }}</span>
          <span v-else-if="enterpriseName == null" class="main-title"
            >数字孪生</span
          >
        </div>
        <span class="subtitle">数据科学平台</span>
      </div>
      <ul class="head-list">
        <li>
          <router-link to="/Home" class="list-item">首页</router-link>
        </li>
        <li>
          <router-link to="/Case" class="list-item">案例</router-link>
        </li>
        <li>
          <router-link to="/Training" class="list-item">实训</router-link>
        </li>
        <!-- <li>
          <router-link to="/Review" class="list-item">评价</router-link>
        </li> -->
        <li>
          <router-link to="/Dynamic" class="list-item">动态</router-link>
        </li>
        <li>
          <router-link to="/PersonalCenter" class="list-item"
            >个人中心</router-link
          >
        </li>
        <li>
          <router-link to="/Help" class="list-item">帮助</router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      mainLogo:
        "/img/main-logo/" + localStorage.getItem("enterpriseName") + ".svg",
      defaultLogo: "/img/main-logo/default.svg",
      enterpriseName: localStorage.getItem("enterpriseName"),
    };
  },
};
</script>

<style scoped>
/* header */

/* 根据路由自动高亮 */
.head-list li .list-item.router-link-active {
  color: rgba(26, 128, 255, 100);
  font-weight: bold;
}

.header {
  box-shadow: 0px 2px 6px 0px rgb(0 0 0 / 12%);
  border: 1px solid rgba(255, 255, 255, 100);
}

.head-show {
  max-width: 1240px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
}

.head-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.head-show img {
  width: 48px;
  margin-right: 12px;
}

span.main-title {
  color: rgba(0, 53, 146, 100);
  font-size: 28px;
  font-family: SourceHanSansSC-bold;
}

span.subtitle {
  color: rgba(0, 53, 146, 100);
  font-size: 20px;
  font-family: SourceHanSansSC-bold;
  margin-left: 15px;
  margin-top: 5px;
}

.head-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.head-list li {
  margin-right: 30px;
}

.head-list li:last-child {
  margin-right: 0;
}

.head-list li a {
  color: rgba(0, 46, 82, 100);
  font-size: 16px;
  text-align: center;
  font-family: SourceHanSansSC-regular;
}

ul.head-list img {
  width: 30px;
  margin-top: 7px;
  cursor: pointer;
}

.head-list span {
  display: inline-block;
  margin-right: 16px;
  color: rgba(0, 46, 82, 100);
  font-size: 16px;
  font-family: SourceHanSansSC-regular;
}
</style>
