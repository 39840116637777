<template>
  <Header></Header>
  <div class="case-detail" id="caseDetail">
    <div class="titleContent">
      <text class="title">
        {{ title }}
      </text>
      <el-button
        type="primary"
        @click="forkCase()"
        v-loading.fullscreen="fullscreenLoading"
        >Fork案例</el-button
      >
    </div>

    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="内容" name="first">
        <div class="casedetail">
          <iframe
            :src="route"
            frameborder="0"
            style="width: 100%; height: 600px; border-radius: 2px"
          ></iframe>
        </div>
      </el-tab-pane>
      <el-tab-pane label="数据" name="second">
        <el-dialog title="温馨提示" v-model="dialogVisible" width="30%">
          <span
            >目前暂不支持在线展示数据集,如需要编辑案例，请先fork案例后，在个人中心打开fork的案例。</span
          >
          <template #footer>
            <span class="dialog-footer">
              <el-button type="primary" @click="dialogVisible = false"
                >我知道了</el-button
              >
            </span>
          </template>
        </el-dialog>
      </el-tab-pane>
    </el-tabs>
  </div>
  <Footer></Footer>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import { ElMessage } from "element-plus";
export default {
  name: "CaseDetail",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      fullscreenLoading: false,
      dialogVisible: false,
      activeName: "first",
      route: "",
      title: "",
    };
  },
  methods: {
    handleClick(tab, event) {
      if (tab.index == 1) {
        this.dialogVisible = true;
      }
    },
    //fork案例
    forkCase() {
      this.fullscreenLoading = true;
      this.axios({
        method: "get",
        url: "/project/forkProject",
        params: {
          userId: localStorage.getItem("userId"),
          username: localStorage.getItem("username"),
          projectId: this.$route.query.projectId,
          projectName: this.title,
        },
      }).then((Response) => {
        this.fullscreenLoading = false;
        ElMessage.success({
          message: Response.data.message,
          type: "success",
        });
      });
    },
  },

  created() {
    this.$notify({
      message: "赶快选一个你喜欢的案例Fork回家吧！",
      offset: 100,
    });
    //案例存放目录必须为 public/Case/ 下面
    // 案例文件名称必须和案例title一致
    var title = this.$route.query.title;
    this.title = title;
    this.route = "Case/" + title + ".html";
  },
};
</script>

<style scoped>
.titleContent {
  display: flex;
  margin: 20px 0;
}
.titleContent .title {
  font-size: 28px;
  margin-right: 50px;
}

#caseDetail /deep/.el-button--primary {
  color: #fff;
  background-color: #06f;
  border-color: #409eff;
}

#caseDetail /deep/ .el-tabs__header {
  padding: 0;
  position: relative;
  margin: 0 0 30px;
}
#caseDetail /deep/.el-tabs__nav-wrap::after {
  height: 2px;
  background-color: #1a80ff;
  width: 100%;
}
#caseDetail /deep/.el-tabs__nav-scroll {
  margin-left: 20px;
}
#caseDetail /deep/.el-tabs__item {
  padding: 0 60px 0 0;
  height: 45px;
  font-size: 20px;
  font-weight: 540;
}
#caseDetail /deep/.el-tabs__item.is-active {
  font-weight: bold;
  color: #1a80ff;
}
.case-detail {
  max-width: 1240px;
  margin: 0 auto;
  min-height: 600px;
}
</style>