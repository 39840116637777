<template>
  <Header></Header>
  <div id="SysReviewDetail">
    <div class="SysReviewDetail-content">
      <h2>{{ sys_evalution_detail_name }}</h2>
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="标准一 : ">
          <el-input
            type="textarea"
            v-model="sys_evalution_detail.criterionOne"
          ></el-input>
        </el-form-item>
        <el-form-item label="标准二 : ">
          <el-input
            type="textarea"
            v-model="sys_evalution_detail.criterionTwo"
          ></el-input>
        </el-form-item>
        <el-form-item label="标准三 : ">
          <el-input
            type="textarea"
            v-model="sys_evalution_detail.criterionThree"
          ></el-input>
        </el-form-item>
        <el-form-item label="标准四 : ">
          <el-input
            type="textarea"
            v-model="sys_evalution_detail.criterionFour"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
  </div>
  <Footer></Footer>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
export default {
  name: "SysReviewDetail",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      sys_evalution_id: "",
      sys_evalution_detail: "",
    };
  },
  created() {
    this.sys_evalution_id = this.$route.query.sys_evalution_id;
    this.axios
      .get("/sysEvaluation/selectOne", {
        params: {
          id: this.sys_evalution_id,
        },
      })
      .then((response) => (this.sys_evalution_detail = response.data));
  },
};
</script>

<style scoped>
.SysReviewDetail-content {
  max-width: 1240px;
  margin: 0 auto;
  min-height: 600px;
  padding: 35px 0 88px;
}
</style>