<template>
  <Header></Header>
  <div id="review">
    <div class="review-content">
      <div class="review-info-box">
        <el-tabs v-model="activeName" @click="handleClick">
          <el-tab-pane label="系统评价" name="first">
            <div class="review-con">
              <div class="review-info">
                <div
                  class="review-item"
                  v-for="item in sys_evaluation"
                  :key="item.title"
                >
                  <router-link
                    :to="{
                      path: '/sysReviewDetail',
                      query: {
                        sys_evalution_id: item.sysId,
                      },
                    }"
                  >
                    <div class="review-item-img">
                      <img
                        src="@/assets/img/fas_fa-envelope_Copy_4.svg"
                        alt=""
                      />
                    </div>
                    <div class="review-item-text">
                      <h3>{{ item.traName }}</h3>
                      <span>{{ item.createTime }}</span>
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="专家评价" name="second">
            <div class="review-con">
              <div class="review-info">
                <div
                  class="review-item"
                  v-for="item in exp_evaluation"
                  :key="item.title"
                >
                  <router-link
                    :to="{
                      path: '/expReviewDetail',
                      query: {
                        exp_evalution_id: item.expId,
                      },
                    }"
                  >
                    <div class="review-item-img">
                      <img
                        src="@/assets/img/fas_fa-envelope_Copy_4.svg"
                        alt=""
                      />
                    </div>
                    <div class="review-item-text">
                      <h3>{{ item.traName }}</h3>
                      <span>{{ item.createTime }}</span>
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
  <Footer></Footer>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
export default {
  name: "Review",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      activeName: "first",
      username: localStorage.getItem("username"),
      userId: localStorage.getItem("userId"),
      exp_evaluation: "",
      sys_evaluation: "",
    };
  },
  methods: {
    handleClick(tab) {
      if (tab.srcElement.outerText == "系统评价") {
        if (this.sys_evaluation == "") {
          this.openSys();
        }
      }
      if (tab.srcElement.outerText == "专家评价") {
        if (this.exp_evaluation == "") {
          if (this.sys_evaluation == "") {
            this.openSys();
          } else {
            this.openExp();
          }
        }
      }
    },
    openSys() {
      this.$notify({
        title: "温馨提示",
        message: "你似乎还没提交过实训结果，快提交结果让我给你打个分吧！",
        offset: 100,
      });
    },
    openExp() {
      this.$notify({
        title: "温馨提示",
        message: "不要着急,专家正在评分路上！",
        offset: 100,
      });
    },
  },
  created() {
    this.axios
      .get("/expEvaluation/selectExpEvaByUserId", {
        params: {
          userId: this.userId,
        },
      })
      .then((response) => (this.exp_evaluation = response.data));
    this.axios
      .get("/sysEvaluation/selectSysEvaByUserId", {
        params: {
          userId: this.userId,
        },
      })
      .then((response) => (this.sys_evaluation = response.data));
  },
};
</script>

<style scoped>
#review /deep/.el-tabs__item {
  padding: 0 60px 0 0;
  height: 45px;
  font-size: 20px;
  font-weight: 540;
}

#review /deep/.el-tabs__active-bar {
  height: 3px;
}

#review /deep/.el-tabs__item.is-active {
  font-weight: bold;
  color: #1a80ff;
}

#review /deep/.el-tabs__nav-wrap::after {
  height: 2px;
  background-color: #1a80ff;
}

/* review */

.review-content {
  max-width: 1240px;
  margin: 0 auto;
  min-height: 600px;
  padding: 35px 0 88px;
}

.review-info {
  display: flex;
  flex-wrap: wrap;
}

.review-info-box .review-con:first-child {
  display: block;
}

.review-item {
  width: 346px;
  height: 120px;
  margin-top: 40px;
  margin-right: 98px;
  border: 1px solid rgba(187, 187, 187, 100);
}

.review-item a {
  display: flex;
  align-items: center;
}

.review-item:nth-child(3n) {
  margin-right: 0;
}

.review-item-img {
  width: 100px;
  height: 120px;
  border-right: 1px solid rgba(187, 187, 187, 100);
  background-color: rgba(241, 248, 255, 100);
  position: relative;
}

.review-item-img img {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.review-item-text {
  width: 226px;
  padding-left: 16px;
}

.review-item-text h3 {
  color: rgba(16, 16, 16, 100);
  font-size: 18px;
  font-family: Roboto-bold;
  margin-bottom: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.review-item-text span {
  color: rgba(75, 77, 83, 100);
  font-size: 14px;
  font-family: Roboto;
}
</style>
