import ElementPlus from 'element-plus'
import 'element-plus/lib/theme-chalk/index.css'
import { ElMessage } from "element-plus";
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'

createApp(App).use(store).use(router).use(VueAxios, axios).use(ElementPlus).mount('#app')


//设置axios请求头加入token
axios.interceptors.request.use(function(config) {

    config.baseURL = "http://120.55.69.196:9001"
    // config.baseURL = "http://localhost:9001"

    config.headers['token'] = localStorage.getItem('token')
    if (localStorage.getItem('realname') != null) {
        // config.headers['realname'] = localStorage.getItem('realname') 中文会报错
        config.headers['realname'] = "authority"
    }
    return config
})

axios.interceptors.response.use(
    response => { /*设置toekn过期的跳转操作*/
        let data = response.data;
        if (data.code == 10000) { /* code == 10000 需要和后端jwt拦截器约定一致 */
            router.push({ path: "/login" });
        }
        if (data.code == 21000) { /* code == 21000 需要和后端拦截器约定一致 */
            ElMessage({
                showClose: true,
                message: data.message,
                type: 'error',
            })
        }
        return response;
    });