<template>
  <Header></Header>
  <!-- 轮播图区 -->
  <div class="home-banner">
    <img src="@/assets/img/home-banner.png" alt="" />
    <div class="text">
      <h3>优质 便捷 高效</h3>
      <p>
        数据科学平台支持在线编程，所有操作自动保存到云端。
        平台内含经典的数据科学与机器学习相关案例。
      </p>
    </div>
  </div>
  <!-- content区 -->
  <div class="home">
    <div class="home-content">
      <div class="item case">
        <div class="img">
          <img src="@/assets/img/home01.png" alt="" />
        </div>
        <div class="text">
          <div class="title">
            <img src="@/assets/img/fas_fa-chart-line.svg" alt="" />
            <h3>案例分析</h3>
          </div>
          <div class="des">
            <p>整合大量实用数据科学项目</p>
            <p>提供最全面的数据科学案例</p>
          </div>
          <div class="button">
            <router-link to="/Case">浏览项目</router-link>
          </div>
        </div>
      </div>
      <div class="item news">
        <div class="text">
          <div class="title">
            <img src="@/assets/img/fas_fa-random.svg" alt="" />
            <h3>实训分析</h3>
          </div>
          <div class="des">
            <p>项目与实训资讯实时更新</p>
            <p>提供全面的数据科学资讯</p>
          </div>
          <div class="button">
            <router-link to="/Training">浏览实训</router-link>
          </div>
        </div>
        <div class="img">
          <img src="@/assets/img/home02.png" alt="" />
        </div>
      </div>
      <div class="item intro">
        <h3>平台简介</h3>
        <div class="text">
          <p>
            数据科学平台由厦门数字孪生信息科技有限公司开发。平台提供数据科学案例学习、实训案例练习、资讯浏览等功能。平台支持在线编码与调试，无需安装编程环境。
          </p>
          <p>
            案例收录了数据科学建模领域的典型案例。案例提供详细的赛题解析，算法建模流程和性能测试功能。
            实训提供在线建模和评分的功能。用户可以在线组队、提交实训结果，查看得分，通过建模分析与系统评价不断优化模型效果。
            资讯包含平台更新动态、机器学习建模调参技巧、数据科学领域研究成果等资讯，供用户闲暇时浏览。
          </p>
        </div>
      </div>
    </div>
  </div>
  <Footer></Footer>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
export default {
  name: "Home",
  components: {
    Header,
    Footer,
  },
  created() {
    this.$notify({
      message: "欢迎来到 数字孪生-数据科学平台！",
      offset: 100,
    });
  },
};
</script>

<style scoped>
/* home */
.home-banner {
  text-align: center;
  position: relative;
}

.home-banner img {
  width: 100%;
}

.home-banner .text {
  position: absolute;
  top: 160px;
  right: 218px;
}

@media only screen and (max-width: 1920px) {
  .home-banner .text {
    right: 436px;
  }
}

@media only screen and (max-width: 1440px) {
  .home-banner .text {
    right: 218px;
  }
}

.home-banner .text h3 {
  color: rgba(255, 255, 255, 100);
  font-size: 60px;
  font-weight: 500;
  font-family: SourceHanSansSC-regular;
  margin-bottom: 46px;
  display: inline-block;
}

.home-banner .text p {
  width: 595px;
  color: rgba(255, 255, 255, 100);
  font-size: 22px;
  text-align: center;
  font-family: SourceHanSansSC-regular;
  display: inline-block;
  margin-left: 130px;
}

.home {
  background-color: #f7f9ff;
}

.home-content {
  padding-top: 40px;
  max-width: 1246px;
  margin: 0 auto;
}

.home-content h3 {
  text-align: center;
  color: #1e2024;
  font-size: 28px;
  font-family: Roboto-bold;
  position: relative;
  display: inline-block;
  margin-bottom: 36px;
}

.home-content h3::after {
  position: absolute;
  content: "";
  width: 280px;
  height: 1px;
  background-color: rgba(218, 224, 232, 100);
  left: 0;
  top: 44px;
}

.home-content .item {
  background: #fff;
  padding-bottom: 50px;
}

.item.case,
.item.news {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}

.home-content .item .text img {
  vertical-align: middle;
  margin-right: 24px;
}

.home-content .item .text .des {
  padding-left: 50px;
}

.home-content .item p {
  color: #4b4d53;
  font-size: 16px;
  font-family: SourceHanSansSC-regular;
}

.home-content .item:last-child p {
  text-align: left;
}

.home-content .button {
  width: 94px;
  margin: 0 auto;
  height: 36px;
  line-height: 32px;
  border-radius: 2px 2px 2px 2px;
  background-color: rgba(86, 119, 252, 100);
  text-align: center;
  box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.24);
}

.button a {
  color: rgba(255, 255, 255, 100);
  font-size: 14px;
  text-align: center;
  font-family: Helvetica-regular;
}

.item.case .img {
  margin-right: 55px;
}

.item .button {
  margin: 72px 0 0 50px;
}

.item.case p {
  margin-bottom: 10px;
}

.item.news {
  padding-left: 128px;
}

.item.news .text {
  margin-right: 120px;
}

.item.news p {
  margin-bottom: 10px;
}

.item.intro h3 {
  display: block;
  margin-bottom: 38px;
  padding-top: 48px;
}

.item.intro h3::before {
  position: absolute;
  content: "";
  width: 280px;
  height: 1px;
  background-color: rgba(218, 224, 232, 100);
  left: 250px;
  top: 68px;
}

.item.intro h3::after {
  position: absolute;
  content: "";
  width: 280px;
  height: 1px;
  background-color: rgba(218, 224, 232, 100);
  left: 700px;
  top: 68px;
}

.item.intro .text {
  width: 725px;
  margin: 0 auto;
}

.item.intro p {
  margin-bottom: 24px;
}

.item.intro .button {
  margin: 50px auto 0;
}
</style>
