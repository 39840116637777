<template>
  <Header></Header>
  <div id="traningdetail">
    <div class="traindetail-content">
      <div class="button">
        <span class="train-title" @click="ifCanCreateTeam()">创建队伍</span>
        <span class="train-title" @click="checkJoinTeam()">加入队伍</span>
        <span class="train-title" @click="myTeam()">我的队伍</span>
        <span class="train-title" @click="goTrain()">参加实训</span>
        <!-- 实训排名在实训结束后显示 -->
        <span class="train-title" @click="ifCanViewRank()">实训排名</span>
      </div>
      <!-- 实训内容区 -->
      <div class="train-content">
        <h1 class="traName">{{ trainingDetail.traName }}</h1>
        <div class="markdown-body" v-html="traContentMD"></div>
      </div>

      <!-- 对话层 -->
      <div class="dialog">
        <!-- 创建队伍 -->
        <el-dialog
          title="创建队伍"
          v-model="dialogCreateTeamVisible"
          width="30%"
        >
          <el-form>
            <el-form-item label="队伍名称">
              <el-input v-model="createTeamName"></el-input>
            </el-form-item>
          </el-form>
          <template #footer>
            <span class="dialog-footer">
              <el-button @click="dialogCreateTeamVisible = false"
                >取 消</el-button
              >
              <el-button type="primary" @click="checkCreateTeam()"
                >确 定</el-button
              >
            </span>
          </template>
        </el-dialog>
        <!-- 加入队伍 -->
        <el-dialog title="加入队伍" v-model="dialogJoinTeamVisible" width="30%">
          <el-form>
            <el-form-item label="选择队伍">
              <el-select v-model="selectTeamId" placeholder="请选择队伍">
                <el-option
                  v-for="item in joinTeamList"
                  :key="item.teamId"
                  :label="item.teamName"
                  :value="item.teamId"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <template #footer>
            <span class="dialog-footer">
              <el-button @click="dialogJoinTeamVisible = false"
                >取 消</el-button
              >
              <el-button type="primary" @click="joinTeam()">确 定</el-button>
            </span>
          </template>
        </el-dialog>
        <!-- 我的队伍 -->
        <el-dialog title="我的队伍" v-model="dialogMyTeamVisible">
          <el-descriptions :column="2" border>
            <el-descriptions-item>
              <template #label>
                <i class="el-icon-tickets"></i>
                队伍名称
              </template>
              <el-tag>{{ myTeamInfo.teamName }}</el-tag>
            </el-descriptions-item>
            <el-descriptions-item>
              <template #label>
                <i class="el-icon-user"></i>
                队长
              </template>
              {{ myTeamInfo.captainName }}
            </el-descriptions-item>
          </el-descriptions>

          <el-table :data="myTeamInfo.memberList" stripe border>
            <el-table-column type="index" align="center"></el-table-column>
            <el-table-column
              property="username"
              label="队伍成员"
              align="center"
            ></el-table-column>
          </el-table>
        </el-dialog>
        <!-- 实训排名 -->
        <el-dialog
          :title="trainingDetail.traName"
          center
          width="80%"
          v-model="dialogTableVisible"
        >
          <el-tabs
            tab-position="left"
            style="height: 400px"
            v-model="activeName"
          >
            <el-tab-pane label="实训用户排名" name="first">
              <el-table
                :data="trainingRankDataFirst"
                border
                height="400"
                stripe
              >
                <el-table-column
                  type="index"
                  label="名次"
                  align="center"
                  width="80"
                >
                </el-table-column>
                <el-table-column
                  property="username"
                  label="用户名"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  property="maxScore"
                  label="成绩"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  property="subTime"
                  label="提交时间"
                  align="center"
                >
                </el-table-column>
              </el-table>
            </el-tab-pane>
            <el-tab-pane label="队伍最高分排名" name="second">
              <el-table
                :data="trainingRankDataSecond"
                border
                height="400"
                stripe
                :default-sort="{ prop: 'maxScore', order: 'descending' }"
              >
                <el-table-column
                  type="index"
                  label="名次"
                  align="center"
                  width="80"
                >
                </el-table-column>
                <el-table-column
                  property="teamName"
                  label="队名"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  property="maxScore"
                  label="成绩"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  property="subTime"
                  label="提交时间"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  property="username"
                  label="用户名"
                  align="center"
                >
                </el-table-column>
              </el-table>
            </el-tab-pane>
            <el-tab-pane label="队伍平均分排名" name="third">
              <el-table
                :data="trainingRankDataThird"
                border
                height="400"
                stripe
                :default-sort="{ prop: 'avgScore', order: 'descending' }"
              >
                <el-table-column
                  type="index"
                  label="名次"
                  align="center"
                  width="80"
                >
                </el-table-column>
                <el-table-column
                  property="teamName"
                  label="队名"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  property="avgScore"
                  label="团队平均成绩"
                  align="center"
                >
                </el-table-column>
              </el-table>
            </el-tab-pane>
          </el-tabs>
        </el-dialog>
      </div>
    </div>
  </div>
  <Footer></Footer>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import MarkDownIt from "markdown-it";
import "github-markdown-css";
import { ElMessage } from "element-plus";
export default {
  name: "TraningDetail",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      Ranktitle: "实训排名",
      activeName: "first",
      trainingRankDataFirst: [],
      trainingRankDataSecond: [],
      trainingRankDataThird: [],
      dialogMyTeamVisible: false,
      myTeamInfo: {},
      dialogCreateTeamVisible: false,
      createTeamName: "",
      dialogJoinTeamVisible: false,
      joinTeamList: [],
      selectTeamId: "",
      dialogTableVisible: false,
      trainingDetail: "",
      //0（未加入队伍）1(已加入队伍)
      ifJoinTeam: 0,
      userId: localStorage.getItem("userId"),
    };
  },

  computed: {
    traContentMD() {
      //初始化时候不存在 页面会报错
      if (this.trainingDetail.traContent != null) {
        const md = new MarkDownIt();
        const result = md.render(this.trainingDetail.traContent);
        return result;
      }
    },
  },

  methods: {
    myTeam() {
      //验证是否加入过队伍
      if (this.ifJoinTeam != 1) {
        ElMessage({
          showClose: true,
          message: "你还未加入队伍",
          type: "error",
        });
        return;
      }

      this.axios
        .get("/training/myTeamInfo", {
          params: {
            traId: this.trainingDetail.traId,
            userId: localStorage.getItem("userId"),
          },
        })
        .then((response) => (this.myTeamInfo = response.data));

      this.dialogMyTeamVisible = true;
    },

    ifCanCreateTeam() {
      //验证是否加入过队伍
      if (this.ifJoinTeam == 1) {
        ElMessage({
          showClose: true,
          message: "你已加入队伍了，不可再创建队伍",
          type: "error",
        });
        return;
      }

      this.dialogCreateTeamVisible = true;
    },

    checkCreateTeam() {
      //验证队伍名不为空
      if (this.createTeamName == "") {
        ElMessage({
          showClose: true,
          message: "队伍名不可以为空",
          type: "error",
        });
        return;
      }

      //还需要验证队伍名是否重复
      this.axios
        .get("/training/newTeamName", {
          params: {
            traId: this.trainingDetail.traId,
            teamName: this.createTeamName,
          },
        })
        .then((response) => {
          //队伍名已经存在
          if (response.data.code == "400") {
            ElMessage({
              showClose: true,
              message: response.data.msg,
              type: "error",
            });
            return;
          }
          if (response.data.code == "200") {
            //验证通过 新增队伍
            this.createNewTeam();
          }
        });
    },

    //新建队伍
    createNewTeam() {
      this.axios
        .get("/training/createTeam", {
          params: {
            traId: this.trainingDetail.traId,
            userId: localStorage.getItem("userId"),
            teamName: this.createTeamName,
          },
        })
        .then((response) => {
          ElMessage({
            showClose: true,
            message: response.data.msg,
            type: "success",
          });
          //创建队伍后 标记1
          this.ifJoinTeam = 1;
          this.dialogCreateTeamVisible = false;
          //重置createTeamName
          this.createTeamName = "";
        });
    },

    checkJoinTeam() {
      if (this.ifJoinTeam == 1) {
        ElMessage({
          showClose: true,
          message: "你已经有队伍了，不可以重复组队",
          type: "error",
        });
        return;
      }
      this.dialogJoinTeamVisible = true;
    },

    joinTeam() {
      //判断用户是否选择了队伍
      if (this.selectTeamId == "") {
        ElMessage({
          showClose: true,
          message: "请选择队伍",
          type: "error",
        });
        return;
      }

      this.axios
        .get("/training/joinTeam", {
          params: {
            traId: this.trainingDetail.traId,
            userId: localStorage.getItem("userId"),
            teamId: this.selectTeamId,
          },
        })
        .then((response) => {
          ElMessage({
            showClose: true,
            message: response.data.msg,
            type: "success",
          });
          //组队后 标记1
          this.ifJoinTeam = 1;
          this.dialogJoinTeamVisible = false;
        });
    },
    goTrain() {
      if (this.ifJoinTeam == 0) {
        ElMessage({
          showClose: true,
          message: "请先选择你的队伍，再参加比赛",
          type: "error",
        });
        return;
      }
      /*
       * 参加实训 需要判断用户是否时添加该实训
       */
      this.axios.get("/training/insertTraining", {
        params: {
          userId: this.userId,
          traId: this.trainingDetail.traId,
        },
      });

      this.$router.push({
        path: "/personalCenterTrainingDetail",
        query: {
          userId: this.userId,
          trainingId: this.trainingDetail.traId,
        },
      });
    },
    ifCanViewRank() {
      this.rank();
    },
    rank() {
      this.axios
        .get("/training/selectTrainingRankByUser", {
          params: {
            traId: this.trainingDetail.traId,
          },
        })
        .then((response) => (this.trainingRankDataFirst = response.data));
      this.dialogTableVisible = true;
      this.axios
        .get("/training/selectTrainingRankByTeamMaxScore", {
          params: {
            traId: this.trainingDetail.traId,
          },
        })
        .then((response) => (this.trainingRankDataSecond = response.data));
      this.axios
        .get("/training/selectTrainingRankByTeamAverageScore", {
          params: {
            traId: this.trainingDetail.traId,
          },
        })
        .then((response) => (this.trainingRankDataThird = response.data));
    },
  },

  created() {
    this.$notify({
      message: "快来参加实训，看看你的实力吧！",
      offset: 100,
    });
    this.axios
      .get("/training/traDetail", {
        params: {
          traId: this.$route.query.trainingId,
          userId: this.userId,
        },
      })
      .then((response) => (this.trainingDetail = response.data));

    this.axios
      .get("/training/ifJoinTeam", {
        params: {
          traId: this.$route.query.trainingId,
          userId: localStorage.getItem("userId"),
        },
      })
      .then((response) => (this.ifJoinTeam = response.data.ifJoinTeam));

    this.axios
      .get("/training/selectAllTeamByTrainId", {
        params: {
          traId: this.$route.query.trainingId,
        },
      })
      .then((response) => (this.joinTeamList = response.data));
  },
};
</script>

<style scoped>
/* train-detail */

.traindetail-content {
  max-width: 1240px;
  margin: 0 auto;
  min-height: 600px;
  padding: 40px 0 80px;
}

.traindetail-content h3 {
  color: rgba(16, 16, 16, 100);
  font-size: 24px;
  font-family: Roboto;
  margin: 48px 0 40px;
  border-left: 5px solid rgba(0, 102, 255, 100);
  padding-left: 12px;
}

.train-content .traName {
  text-align: center;
}
.button span.train-title {
  display: inline-block;
  margin-right: 22px;
  width: 96px;
  height: 36px;
  line-height: 36px;
  border-radius: 2px 2px 2px 2px;
  background-color: rgba(26, 128, 255, 100);
  color: rgba(255, 255, 255, 100);
  font-size: 14px;
  text-align: center;
  cursor: pointer;
}

.button span.train-join {
  display: inline-block;
  margin-right: 22px;
  width: 96px;
  height: 36px;
  line-height: 36px;
  border-radius: 2px 2px 2px 2px;
  border: #b8b8b8 1px solid;
  color: rgba(0, 0, 0, 1);
  font-size: 14px;
  text-align: center;
  font-family: Helvetica-regular;
  cursor: pointer;
}
.button span.train-rank {
  display: inline-block;
  width: 96px;
  height: 36px;
  line-height: 36px;
  border-radius: 2px 2px 2px 2px;
  border: #b8b8b8 1px solid;
  color: rgba(0, 0, 0, 1);
  font-size: 14px;
  text-align: center;
  font-family: Helvetica-regular;
  cursor: pointer;
}
.train-content {
  margin-top: 50px;
}
.captain {
  margin: 0 0 20px 0;
}
</style>