<template>
  <Header></Header>
  <div class="help-content">
    <h1 class="help-header">数据科学实训平台帮助文档</h1>
    <div class="markdown-body" v-html="helpMd"></div>
  </div>
  <Footer></Footer>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import MarkDownIt from "markdown-it";
import "github-markdown-css";
export default {
  name: "Help",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      helpMd: "",
    };
  },
  created() {
    this.axios.get("/help").then((response) => {
      let md = new MarkDownIt();
      this.helpMd = md.render(response.data);
    });
  },
};
</script>

<style scoped>
.help-content {
  max-width: 1240px;
  margin: 0 auto;
  min-height: 600px;
  padding: 35px 0 88px;
}
.help-content .help-header {
  text-align: center;
}
</style>