<template>
  <div class="myinfo">
    <el-descriptions class="margin-top" title="基本信息" :column="3" border>
      <template #extra>
        <el-button type="primary" size="small" @click="dialogFormVisible = true"
          >修改密码</el-button
        >
      </template>
      <el-descriptions-item>
        <template #label>
          <i class="el-icon-user"></i>
          用户名
        </template>
        {{ myInfo.username }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label>
          <i class="el-icon-user"></i>
          姓名
        </template>
        {{ myInfo.realname }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label>
          <i class="el-icon-office-building"></i>
          企业/学校
        </template>
        {{ myInfo.enterpriseName }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label>
          <i class="el-icon-s-flag"></i>
          班级
        </template>
        {{ myInfo.groupName }}
      </el-descriptions-item>
    </el-descriptions>
  </div>
  <el-dialog title="修改密码" v-model="dialogFormVisible" width="30%">
    <el-form
      :model="ruleForm"
      status-icon
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
    >
      <el-form-item label="密码" prop="pass">
        <el-input
          type="password"
          v-model="ruleForm.pass"
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <el-form-item label="确认密码" prop="checkPass">
        <el-input
          type="password"
          v-model="ruleForm.checkPass"
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="resetForm('ruleForm')">重置</el-button>
        <el-button type="primary" @click="changeInfo('ruleForm')"
          >提交</el-button
        >
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
export default {
  name: "MyInfo",
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm.pass) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      myInfo: "",
      username: localStorage.getItem("username"),
      dialogFormVisible: false,
      ruleForm: {
        pass: "",
        checkPass: "",
      },
      rules: {
        pass: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
          {
            pattern: /^[a-zA-Z0-9@._-]{3,16}$/,
            message: "请输入3到16位[字母，数字，符号（@._-）]的密码",
            trigger: "blur",
          },
        ],
        checkPass: [
          {
            validator: validatePass,
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    changeInfo(ruleForm) {
      this.$refs[ruleForm].validate((valid) => {
        if (valid) {
          this.axios
            .get("/user/changeMyInfo", {
              params: {
                userId: localStorage.getItem("userId"),
                password: this.ruleForm.pass,
              },
            })
            .then(
              this.$notify({
                title: "提示",
                message: "密码修改成功",
                type: "success",
                offset: 100,
              })
            );
          this.dialogFormVisible = false;
          this.$refs[ruleForm].resetFields();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
  created() {
    this.axios
      .get("/user/selectMyInfo", {
        params: {
          userId: localStorage.getItem("userId"),
        },
      })
      .then((Response) => (this.myInfo = Response.data));
  },
};
</script>

<style scoped>
.myinfo {
  max-width: 1240px;
  margin: 0 auto;
  min-height: 600px;
  padding: 40px 0 80px;
}
</style>
