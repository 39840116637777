<template>
  <router-view />
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
/* 登录界面填充100% */
html,
body,
#app {
  height: 100%;
  margin: 0px;
  padding: 0px;
}

li {
  list-style-type: none;
}
/* 删除超链接下划线 */
a {
  text-decoration: none;
}

body {
  padding: 0;
  margin: 0;
}

h3,
p,
span,
ul {
  margin: 0;
  padding: 0;
}
</style>
