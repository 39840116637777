<template>
  <div>
    <div class="footer">
      <div class="footer-content">
        <div class="footer-info">
          <div class="footer-info-img">
            <img src="@/assets/img/focus_.png" alt="" />
          </div>
          <div class="footer-info-text">
            <h3>厦门数字孪生信息科技有限公司</h3>
            <p>地址：厦门火炬高新区软件园华讯楼A区2F-G</p>
            <p>电话：0592-2125668</p>
            <p>邮箱：support@ditwin.cn</p>
          </div>
        </div>

        <!-- <div class="footer-list">
                <span>平台资讯</span>
                <ul>
                    <li><a href="/">平台介绍</a></li>
                    <li><a href="/">视讯资讯</a></li>
                </ul>
            </div>
            <div class="footer-list sec">
                <span>平台功能</span>
                <ul>
                    <li><a href="/">实训项目</a></li>
                    <li><a href="/">个人中心</a></li>
                    <li><a href="/">后台管理</a></li>
                </ul>
            </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style scoped>
/* footer */
.footer {
  background-color: rgba(0, 31, 45, 100);
}

.footer-content {
  display: flex;
  padding: 70px 0 90px 0;
  max-width: 1240px;
  margin: 0 auto;
}

.footer-info {
  display: flex;
  margin-right: 590px;
}

.footer-info-img {
  margin-right: 24px;
}

.footer-info-img img {
  width: 118px;
  height: 118px;
}

.footer-info h3 {
  color: rgba(255, 255, 255, 100);
  font-size: 20px;
  font-weight: 500;
  font-family: SourceHanSansSC-regular;
  margin-bottom: 16px;
}

.footer-info p {
  color: rgba(255, 255, 255, 100);
  font-size: 14px;
  font-family: SourceHanSansSC-regular;
  margin-bottom: 5px;
}

.footer-list {
  text-align: center;
  margin-right: 65px;
}

.footer-list.sec {
  margin-right: 0;
}

.footer-list span {
  display: inline-block;
  margin-bottom: 20px;
  color: rgba(255, 255, 255, 100);
  font-size: 20px;
  font-family: SourceHanSansSC-regular;
}

.footer-list li {
  margin-bottom: 10px;
  text-align: right;
}

.footer-list li a {
  color: rgba(255, 255, 255, 100);
  font-size: 14px;
  font-family: SourceHanSansSC-regular;
}
</style>
