<template>
  <div class="login" id="login">
    <el-form
      :model="registerForm"
      :rules="rules"
      ref="registerForm"
      class="login-Form"
    >
      <h2 class="title">注册账号</h2>
      <el-form-item prop="username">
        <el-input
          type="text"
          v-model="registerForm.username"
          placeholder="账号名"
          prefix-icon="el-icon-user-solid"
        ></el-input>
      </el-form-item>
      <el-form-item prop="password">
        <el-input
          type="text"
          v-model="registerForm.password"
          placeholder="密码"
          prefix-icon="el-icon-lock"
        >
        </el-input>
      </el-form-item>
      <el-form-item prop="secretKey">
        <el-input
          type="text"
          v-model="registerForm.secretKey"
          placeholder="注册秘钥"
          prefix-icon="el-icon-key"
        ></el-input>
      </el-form-item>
      <el-button
        type="primary"
        @click="register('registerForm')"
        style="width: 100%"
        >注册</el-button
      >
    </el-form>
  </div>
</template>

<script>
import qs from "qs";
import { ElMessage } from "element-plus";
export default {
  name: "Register",
  data() {
    return {
      registerForm: {
        username: "",
        password: "",
        secretKey: "",
      },
      rules: {
        username: [
          {
            required: true,
            message: "请输入账号名",
            trigger: "blur",
          },
          {
            pattern: /^[a-zA-Z0-9_]{3,16}$/,
            message: "账号名规则，3到16位（字母，数字，下划线）",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
          {
            pattern: /^[a-zA-Z0-9@._-]{3,16}$/,
            message: "请输入3到16位[字母，数字，符号（@._-）]的密码",
            trigger: "blur",
          },
        ],
        secretKey: [
          {
            required: true,
            message: "请输入秘钥",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    register(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.axios({
            url: "/user/register",
            method: "post",
            data: qs.stringify(this.registerForm),
          }).then((response) => {
            if (response.data.msg == "success") {
              ElMessage.success("注册成功");
              //跳转登录页面
              let this_ = this;
              setTimeout(function () {
                this_.$router.push({ path: "/login" });
              }, 1500);
            } else {
              ElMessage.error(response.data.msg);
            }
          });
        } else {
          console.log("注册失败！");
          return false;
        }
      });
    },
  },
};
</script>

<style scoped>
.login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-image: url(/RegisterBackground.jpg);
  background-size: cover;
}

.login-Form {
  border-radius: 6px;
  background: #fff;
  width: 400px;
  padding: 25px 25px 30px 25px;
}

.title {
  margin: 0 auto 30px auto;
  text-align: center;
  color: #707070;
}

#login /deep/ .el-form-item__label {
  text-align: left;
}
</style>